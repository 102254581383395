import store from '../store';

export default (to, from, next) => {
  // console.log(to);
  // console.log(from);
  const isAuthRequired = to.matched.some((route) => route.meta.authRequired);
  // console.log(isAuthRequired && !store.state.user);
  // console.log(isAuthRequired);
  // console.log(!store.state.user);
  // console.log(store.state.user.user);
  if (isAuthRequired && !store.state.user.currentUser) {
    next({
      name: 'home',
      query: {
        redirectFrom: to.fullPath,
      },
    });
  } else if (store.state.user.currentUser && to.name === 'home') {
    next({
      name: 'app',
    });
  } else {
    next();
  }
};
