// import Vue from 'vue';
import firebase from 'firebase';

// const { prototype: { firebase: localFirebase } } = Vue.prototype.firebase;

export default {
  namespaced: true,
  state: {
    currentUser: false,
  },
  getters: {
    user: (state) => state.currentUser,
  },
  mutations: {
    signIn(state, user) {
      state.currentUser = user;
    },
    signOut(state) {
      state.currentUser = false;
    },
  },
  actions: {
    signIn({ commit }, user) {
      commit('signIn', user);
    },
    async signOut({ commit }) {
      await firebase.auth().signOut();
      await commit('signOut');
      window.location.reload();
    },
  },
};
