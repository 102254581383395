import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#212121',
        secondary: '#8f8f8f',
        bgHeaderFooter: '#ffffff',
        accent: '#e61807',
        accentSecondary: '#880a1f',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
      },
      dark: {
        primary: '#fff',
        secondary: '#8f8f8f',
        bgHeaderFooter: '#272727',
        accent: '#e61807',
        accentSecondary: '#880a1f',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
      },
    },
  },
});
