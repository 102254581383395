<template>
  <div id="app">
    <v-app>
    <side-bar v-if="$store.state.user.currentUser"></side-bar>
    <top-bar v-if="$store.state.user.currentUser"></top-bar>
    <router-view></router-view>
    <c-footer></c-footer>
    </v-app>
  </div>
</template>

<script>
import TopBar from '@/components/TopNav.vue';
import SideBar from '@/components/Sidebar.vue';
import CFooter from '@/components/Footer.vue';

export default {
  name: 'App',
  components: {
    TopBar,
    SideBar,
    CFooter,
  },
  data: () => ({
    drawer: null,
  }),
  props: {
    source: String,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 50px;
}
</style>
