const config = {
  apiKey: 'AIzaSyBIiao37PD9HucOEvZC1JMohkFh-9D2Lh8',
  authDomain: 'kurlonapis.firebaseapp.com',
  projectId: 'kurlonapis',
  storageBucket: 'kurlonapis.appspot.com',
  messagingSenderId: '549832967786',
  appId: '1:549832967786:web:51fa68d4ff92260b54ec13',
};

export default config;
