const admin = [
  {
    title: 'Dashboard',
    icon: 'mdi-view-dashboard',
    to: '/app/dashboard',
  },
  {
    title: 'Transactions',
    icon: 'mdi-form-select',
    to: '/app/transactions',
  },
];

export default (user) => {
  let menu = '';
  if (user === 'admin') {
    menu = admin;
    console.log('hi');
  }
  return menu;
};
