import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import config from './config';

const setupFirebase = {
  install(Vue) {
    Vue.prototype.firebase = firebase;
    Vue.prototype.$fb = firebase;
    Vue.prototype.firebaseInit = () => {
      firebase.initializeApp(config);
    };
  },
};

export default setupFirebase;
