<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <login></login>
  </div>
</template>

<script>
// @ is an alias to /src
import Login from './Login.vue';

export default {
  name: 'home',
  components: {
    Login,
  },
};
</script>
