import Home from '../views/Home.vue';
import checkAuth from '../utils/checkAuth';

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    beforeEnter: checkAuth,
  },
  {
    path: '/app',
    name: 'app',
    component: () => import('../views/app'),
    redirect: '/app/dashboard',
    beforeEnter: checkAuth,
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: 'transactions',
        name: 'app-transactions',
        component: () => import(/* webpackChunkName: "HR module" */ '../views/app/transactions'),
      },
      {
        path: 'dashboard',
        name: 'app-dashboard',
        component: () => import('../views/app/home'),
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "signup" */ '../views/Login.vue'),
  },
  {
    path: '*',
    name: 'not-found-error',
    component: () => import(/* webpackChunkName: "not-found-error" */ '../components/NotFound.vue'),
  },
];

export default routes;
